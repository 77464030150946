import React, { useState, useEffect } from "react";
import { Info as InfoIcon } from "lucide-react"; // Lucide-react icon
import "../styles/PricingPlan.css"; // Make sure the order is correct

export default function PricingPlan() {
	const [billingCycle, setBillingCycle] = useState("yearly");
	const [selectedPlan, setSelectedPlan] = useState("small");
	const [additionalRecords, setAdditionalRecords] = useState(0);
	const [additionalParsing, setAdditionalParsing] = useState(0);
	const [totalPrice, setTotalPrice] = useState(0);
	const [isEnterprise, setIsEnterprise] = useState(false);

	const handleFocus = e => {
		if (e.target.value === "0") {
			e.target.value = "";
		}
	};

	const handleBlur = e => {
		if (e.target.value === "") {
			e.target.value = "0";
		}
	};

	const plans = {
		small: {
			name: "Small",
			yearly: 199,
			monthly: 239,
			maxUsers: 5,
			maxRecords: 10000,
		},
		medium: {
			name: "Medium",
			yearly: 499,
			monthly: 599,
			maxUsers: 20,
			maxRecords: 20000,
		},
		large: {
			name: "Large",
			yearly: 799,
			monthly: 959,
			maxUsers: 50,
			maxRecords: 50000,
		},
		enterprise: {
			name: "Enterprise",
			yearly: "Custom",
			monthly: "Custom",
			maxUsers: "Unlimited",
			maxRecords: "Unlimited",
		},
	};

	useEffect(() => {
		if (selectedPlan !== "enterprise") {
			const basePrice =
				billingCycle === "yearly"
					? plans[selectedPlan].yearly
					: plans[selectedPlan].monthly;
			const additionalRecordsPrice = Math.ceil(additionalRecords / 10000) * 40;
			const additionalParsingPrice = Math.ceil(additionalParsing / 200) * 20;
			setTotalPrice(
				basePrice + additionalRecordsPrice + additionalParsingPrice
			);
			setIsEnterprise(false);
		} else {
			setTotalPrice(0);
			setIsEnterprise(true);
		}
	}, [billingCycle, selectedPlan, additionalRecords, additionalParsing]);

	return (
		<div id="pricing-plan" className="container my-5 overflow-visible">
			{/* Hero Section */}
			<h1 className="fs-1 text-center mb-4">Choose a Pricing Plan</h1>
			<div className="text-center mb-4">
				<div className="btn-group" role="group" aria-label="Billing Cycle">
					<input
						type="radio"
						className="btn-check"
						name="billingCycle"
						id="monthly"
						value="monthly"
						checked={billingCycle === "monthly"}
						onChange={() => setBillingCycle("monthly")}
					/>
					<label className="btn btn-custom" htmlFor="monthly">
						Monthly
					</label>

					<input
						type="radio"
						className="btn-check"
						name="billingCycle"
						id="yearly"
						value="yearly"
						checked={billingCycle === "yearly"}
						onChange={() => setBillingCycle("yearly")}
					/>
					<label className="btn btn-custom" htmlFor="yearly">
						Save with Yearly
					</label>
				</div>
			</div>

			{/* Price Plans */}
			<div className="pricing-container mt-4">
				<div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4 mb-5">
					{Object.entries(plans).map(([key, plan]) => (
						<div className="col" key={key}>
							<div
								className={`card h-100 text-center ${plan.name.toLowerCase()} ${
									selectedPlan === key ? "border-primary" : ""
								}`}
								onClick={() => setSelectedPlan(key)}
							>
								<div className="card-body d-flex flex-column justify-content-around">
									<h2 className="card-title text-md-start fw-bold">
										{plan.name}
									</h2>
									<p className="price-text card-text text-md-start fs-4">
										{plan.yearly === "Custom" ? (
											<span className="fw-bold">{"Custom"}</span>
										) : (
											<>
												<span className="fw-bold">
													$
													{billingCycle === "yearly"
														? plan.yearly
														: plan.monthly}
												</span>
												<small>/month</small>
											</>
										)}
									</p>
									<ul className="list-unstyled text-md-start lh-md">
										<li>All Features Included</li>
										<li>Max Users: {plan.maxUsers}</li>
										<li>
											Max Records:{" "}
											{typeof plan.maxRecords === "number"
												? plan.maxRecords.toLocaleString()
												: plan.maxRecords}
										</li>
									</ul>

									<button
										className={`btn p-2 ${
											selectedPlan === key ? "btn-dark" : "btn-outline-primary"
										}`}
										onClick={() => {
											if (key === "enterprise") {
												window.open(
													"https://calendly.com/justasri/aira-enterprise",
													"_blank",
													"noopener,noreferrer"
												);
											} else {
												setSelectedPlan(key);
											}
										}}
									>
										{key === "enterprise"
											? "Get In Touch"
											: selectedPlan === key
											? "Selected"
											: "Select Plan"}
									</button>
								</div>
							</div>
						</div>
					))}
				</div>
			</div>

			{/* Additional Options */}
			<div
				className={`transition-container overflow-visible ${
					isEnterprise ? "hidden" : ""
				}`}
			>
				<div
					className={`card mb-4 additional-options overflow-visible ${
						isEnterprise ? "hidden" : ""
					}`}
				>
					<div className="card-body overflow-visible">
						<h3 className="card-title fw-bold">Additional Options</h3>
						<div className="row mb-4 overflow-visible">
							<div className="col-sm-6 d-flex align-items-center overflow-visible">
								<label
									className="overflow-visible"
									tooltip="$40 per 10,000 Candidates & Contact Imports"
								>
									Additional Records
									<InfoIcon color="#71717a" className="ms-2 overflow-visible" />
								</label>
							</div>

							<div className="col-sm-6 d-flex flex-column align-items-end">
								<div className="text-nowrap text-end w-100">
									${Math.ceil(additionalRecords / 10000) * 40}/mo
								</div>
								<input
									type="number"
									className="form-control mt-2 text-right w-50"
									value={additionalRecords}
									onChange={e => setAdditionalRecords(Number(e.target.value))}
									onFocus={handleFocus}
									onBlur={handleBlur}
									min="0"
								/>
							</div>
						</div>
						<div className="row overflow-visible">
							<div className="col-sm-6 d-flex align-items-center overflow-visible">
								<label
									className="overflow-visible"
									tooltip="$20 per 200 Resume Parsings"
								>
									Additional Parsing
									<InfoIcon color="#71717a" className="ms-2" />
								</label>
							</div>

							<div className="col-sm-6 d-flex flex-column align-items-end">
								<div className="text-nowrap text-end w-100">
									${Math.ceil(additionalParsing / 200) * 20}/mo
								</div>
								<input
									type="number"
									className="form-control mt-2 text-right w-50"
									value={additionalParsing}
									onChange={e => setAdditionalParsing(Number(e.target.value))}
									onFocus={handleFocus}
									onBlur={handleBlur}
									min="0"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* Total Cost */}
			<div
				id="total-cost"
				className={`card text-center total-cost ${
					isEnterprise ? "move-up" : ""
				}`}
			>
				<div className="card-body bg-gradient p-4">
					<div className="d-flex justify-content-between">
						<div className="text-start">
							<h2 className="fw-bold">Total</h2>
							<p>
								Billed yearly at $
								{selectedPlan !== "enterprise"
									? (totalPrice * 12).toFixed(2)
									: "0.00"}
							</p>
						</div>
						<p className="display-4 overflow-hidden fw-bold">
							{selectedPlan !== "enterprise"
								? `$${totalPrice}/mo`
								: "Contact Us"}
						</p>
					</div>

					<div className="mt-4 text-start">
						<button
							className="btn btn-dark btn-lg me-3"
							onClick={() =>
								window.open("https://app.helloaira.io/register", "_blank")
							}
						>
							Start Free Trial
						</button>
						<button
							className="btn btn-outline-primary btn-lg"
							onClick={() =>
								window.open(
									"https://calendly.com/d/ck5g-53m-559/aira-demo",
									"_blank"
								)
							}
						>
							Get in Touch
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}
